<template>
    <main-section>
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit font-tertiary">인기 제품</h2>
            </div>
            <v-tabs centered class="mb-20px mb-md-40px" v-model="popularTab">
                <template v-for="(item, index) in items">
                    <v-tab :key="index">
                        <v-btn large class="rounded-xs">
                            {{ item.tab }}
                        </v-btn>
                    </v-tab>
                </template>
            </v-tabs>
            <v-tabs-items v-model="popularTab">
                <template v-for="(item, index) in items">
                    <v-tab-item eager :key="index">
                        <v-row class="row--lg">
                            <template v-for="(child, index) in item.children">
                                <v-col :key="index" cols="6" lg="3">
                                    <v-hover v-slot="{ hover }">
                                        <v-card tile flat :to="child.link" class="popular-card">
                                            <v-img eager :src="child.image" class="mb-12px mb-md-16px" />
                                            <div class="tit tit--xs mb-6px mb-md-8px">
                                                {{ child.title }}
                                            </div>
                                            <div class="txt tit--xs mb-12px mb-md-16px">
                                                {{ child.text }}
                                            </div>
                                            <div class="popular-card__button d-flex align-center">
                                                <span class="txt txt--xs txt--dark font-weight-medium"> {{ child.title }} 보러 가기 </span>
                                                <v-img max-width="24" :src="hover ? '/images/icon/icon-arrow-right-active.svg' : '/images/icon/icon-arrow-right.svg'" class="ml-8px" />
                                            </div>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </template>
                        </v-row>
                    </v-tab-item>
                </template>
            </v-tabs-items>
        </v-container>
    </main-section>
</template>

<script>
import MainSection from "@/components/client/templates/main-section.vue";

export default {
    components: {
        MainSection,
    },
    data: () => ({
        popularTab: null,
        items: [
            {
                tab: "욕실",
                children: [
                    {
                        title: "세면기",
                        text: "독특한 세면기를 만나보세요. 현대적인 디자인과 고품질 소재가 눈길을 사로잡습니다.",
                        image: "/images/main/popular/bathroom/bathroom-1.jpg",
                        link: "/shop/products?category=bathroom_sink",
                    },
                    {
                        title: "욕실 가구",
                        text: "우아한 욕실 가구는 수납 공간을 제공하고 세련된 포인트를 더해줍니다. 욕실을 웰빙의 오아시스로 바꿔보세요!",
                        image: "/images/main/popular/bathroom/bathroom-2.jpg",
                        link: "/shop/products?category=bathroom_furniture_and_mirrors",
                    },
                    {
                        title: "욕조",
                        text: "독립형이든 내장형이든 상관없이 완벽한 욕조를 찾으실 수 있습니다. 당신의 꿈의 욕실을 위한 최대의 편안함과 세련된 디자인.",
                        image: "/images/main/popular/bathroom/bathroom-3.jpg",
                        link: "/shop/products?category=free_standing_bathtub",
                    },
                    {
                        title: "양변기",
                        text: "저희의 양변기는 혁신적인 기술과 세련된 디자인으로 깊은 인상을 남깁니다. 최고의 편안함을 경험하고 자신만의 개성을 표현해보세요.",
                        image: "/images/main/popular/bathroom/bathroom-4.jpg",
                        link: "/shop/products?category=toilets_and_wcs",
                    },
                ],
            },
            {
                tab: "주방",
                children: [
                    {
                        title: "싱크대",
                        text: "우리의 고품질 싱크대는 기능성과 스타일을 결합하여 인상적인 주방을 만들어줍니다!",
                        image: "/images/main/popular/kitchen/kitchen-1.webp",
                        link: "/shop/products?category=ceramic_sinks",
                    },
                    {
                        title: "주방 수전",
                        text: "우아한 주방 수전은 주방에 디자인과 편안함을 완벽하게 결합합니다.",
                        image: "/images/main/popular/kitchen/kitchen-2.webp",
                        link: "/shop/products?category=kitchen_fittings",
                    },
                    {
                        title: "세라믹 싱크대",
                        text: "스타일리시한 싱크대를 만나보세요. 견고하고 우아하며 모든 주방의 하이라이트가 됩니다!",
                        image: "/images/main/popular/kitchen/kitchen-3.webp",
                        link: "/shop/products?category=ceramic_sinks",
                    },
                    {
                        title: "액세서리",
                        text: "실용적인 주방 액세서리: 싱크대 공간을 최대한 활용하는 데 필요한 모든 것.",
                        image: "/images/main/popular/kitchen/kitchen-4.webp",
                        link: "/shop/products?category=kitchen_fittings",
                    },
                ],
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}
.popular-card {
    .popular-card {
        &__button {
            > span {
                transition: all 0.4s;
            }
            > .v-image {
                transition: all 0.2s ease;
            }
        }
    }
}
@media (min-width: 768px) {
    .v-tab {
        padding: 0 12px;
    }
    .popular-card {
        &:hover {
            .popular-card {
                &__button {
                    > span {
                        color: var(--v-primary-base);
                    }
                    .v-image {
                        transform: translateX(4px);
                    }
                }
            }
        }
    }
}
</style>