var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', [_c('v-container', [_c('v-sheet', {
    staticClass: "section-bg"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/main/ideas.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("욕실 아이디어")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 욕실의 조화로운 디자인과 최적의 기능성을 위해 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" - 다양한 욕실 아이디어를 찾아보세요 ")]), _c('v-btn', {
    staticClass: "white mt-18px mt-md-24px px-20px px-md-26px rounded-xs",
    attrs: {
      "large": "",
      "tile": "",
      "to": "/ideas/bathroom/trends"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("아이디어 보러 가기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }