<template>
    <main-section class="main-story">
        <v-container>
            <v-row class="row--lg">
                <v-col cols="12" md="4">
                    <div class="tit tit--sm font-tertiary font-weight-regular mb-14px mb-md-16px">Villeroy-boch in South Korea</div>
                    <p class="txt txt--sm">
                        1748년부터 Villeroy &amp; Boch는 <br class="d-none d-md-block" />
                        사람들의 삶에 세련미와 스타일을 가져다주었습니다. <br class="d-none d-md-block" />
                        이제 Villeroy &amp; Boch가 대한민국과 함께합니다.
                    </p>
                </v-col>
                <v-col cols="12" md="4">
                    <div class="mb-18px mb-md-24px">
                        <v-row class="row--xs">
                            <v-col cols="auto">
                                <v-img src="/images/icon/icon-address.svg" />
                            </v-col>
                            <v-col>
                                <div class="txt txt--sm">서울시 강남구 봉은사로 226 더북컴퍼니빌딩 1층</div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row align="center" class="row--xs">
                        <v-col cols="auto">
                            <v-img src="/images/icon/icon-phone.svg" />
                        </v-col>
                        <v-col>
                            <div class="txt txt--sm d-inline-block border-b">010-3281-3676</div>
                        </v-col>
                    </v-row>
                    <v-row align="center" class="row--xs">
                        <v-col cols="auto">
                            <v-img src="/images/icon/icon-mail.svg" />
                        </v-col>
                        <v-col>
                            <div class="txt txt--sm d-inline-block border-b">villeroy boch@joeyforlife.com</div>
                        </v-col>
                    </v-row>
                    <!-- <v-btn text large :ripple="false" class="mt-8px pa-0">
                        <span class="txt txt--xs txt--dark font-weight-medium">회사 정보</span>
                        <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                    </v-btn> -->
                </v-col>
                <v-col cols="12" md="4">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.997618149319!2d127.0345016764283!3d37.507974327483126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3ddfc2279bd%3A0x34077c76a1e52a78!2z642U67aB7Lu07Y2864uIIOu5jOuUqQ!5e0!3m2!1sko!2skr!4v1725954572090!5m2!1sko!2skr" width="100%" height="170" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <!-- <v-btn text large :ripple="false" class="mt-8px pa-0">
                        <span class="txt txt--xs txt--dark font-weight-medium">회사 위치</span>
                        <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                    </v-btn> -->
                </v-col>
            </v-row>
        </v-container>
    </main-section>
</template>

<script>
import MainSection from "@/components/client/templates/main-section.vue";

export default {
    components: {
        MainSection,
    },
};
</script>

<style lang="scss" scoped>
</style>