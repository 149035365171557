var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    staticClass: "main-story"
  }, [_c('v-container', [_c('v-sheet', {
    staticClass: "section-bg"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "story-image-wrap"
  }, [_c('v-img', {
    staticClass: "d-md-none",
    attrs: {
      "src": "/images/main/story.jpg"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("브랜드 이야기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 시대를 초월한 우아함, 혁신적인 디자인, "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 뛰어난 품질: 이것이 바로 Villeroy & "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" Boch 브랜드가 1748년부터 상징해 온 것입니다. ")]), _c('v-btn', {
    staticClass: "white mt-18px mt-md-24px px-20px px-md-26px rounded-xs",
    attrs: {
      "large": "",
      "tile": "",
      "to": "/company/history/history"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("더 읽기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }