<template>
    <main-section>
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit font-tertiary">제품 하이라이트</h2>
            </div>
            <v-tabs v-model="highlightsTab" centered class="mb-20px mb-md-40px">
                <template v-for="(item, index) in items">
                    <v-tab :key="index">
                        <v-btn large class="rounded-xs">
                            {{ item.text }}
                        </v-btn>
                    </v-tab>
                </template>
            </v-tabs>
            <v-row>
                <v-col v-for="(product, index) in products" :key="index" cols="12" sm="4" lg="3">
                    <shop-product-card v-bind="{ value: product }" />
                </v-col>
                <v-col cols="12">
                    <div class="product-lists__pagination">
                        <pagination-component :value="page" :count="pageCount" @input="(value) => (page = value)"></pagination-component>
                    </div>
                </v-col>
            </v-row>
            <!-- <v-tabs-items v-model="highlightsTab">
                <template v-for="(product, index) in products">
                    <v-tab-item :key="index">
                        {{ index }}
                        <v-row>
                            <v-col cols="6" sm="4" lg="3">
                                <shop-product-card v-bind="{ value: product }" />
                            </v-col>
                        </v-row>
                        <div class="product-lists__pagination">
                            <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                        </div>
                    </v-tab-item>
                </template>
            </v-tabs-items> -->
        </v-container>
    </main-section>
</template>

<script>
import api from "@/api";
import { DISPLAY_CODES } from "@/assets/variables";
import MainSection from "@/components/client/templates/main-section.vue";
import ShopProductCard from "@/components/client/shop/shop-product-card.vue";
import PaginationComponent from "../pagination-component.vue";

export default {
    components: {
        MainSection,
        ShopProductCard,
        PaginationComponent,
    },
    data: () => ({
        items: [
            { text: "욕조", name: "Bath_and_Wellness" },
            { text: "주방", name: "Kitchen" },
        ],
        highlightsTab: null,
        summary: { totalCount: 0 },
        products: [],

        limit: 4,
        page: 1,
    }),
    computed: {
        categories() {
            return this.$store.state.categories;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
        async search(item) {
            try {
                let category = this.categories.filter(({ code }) => code === item.code)[0];

                let { summary, products } = await api.v1.shop.products.gets({
                    headers: { skip: this.skip, limit: this.limit },
                    params: { _category: category?._id, groups: { $in: [DISPLAY_CODES.MAIN_SHOWS.value] } },
                });

                this.summary = summary;
                this.products = products;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    watch: {
        highlightsTab() {
            this.search(this.items[this.highlightsTab]);
            this.page = 1;
        },
        page() {
            this.search(this.items[this.highlightsTab]);
        }
    },
};
</script>

<style lang="scss" scoped>
.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}

@media (min-width: 768px) {
    .v-tab {
        padding: 0 12px;
    }
}
</style>