<template>
    <v-navigation-drawer v-model="drawer" fixed class="header-drawer">
        <v-container>
            <v-row no-gutters>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn text plain width="24" min-width="24" class="header-menu-button" @click="toggleDrawer">
                        <v-img width="24" src="/images/icon/icon-close.svg" class="header-menu-icon" />
                        <div>Close</div>
                    </v-btn>
                </v-col>
            </v-row>

            <v-tabs v-model="activeTabData" class="navigation-tabs navigation-tabs--1">
                <template v-for="(item, index) in items">
                    <template v-if="item.children">
                        <v-tab :key="index">
                            <span>
                                {{ item.text }}
                            </span>
                        </v-tab>
                    </template>
                    <template v-else>
                        <v-tab :key="index" :to="item.value" class="v-tab--inactive" @click="toggleDrawer">
                            <span>
                                {{ item.text }}
                            </span>
                        </v-tab>
                    </template>
                </template>
            </v-tabs>

            <v-tabs-items v-model="activeTabData">
                <template v-for="(item, index) in items">
                    <v-tab-item :key="index">
                        <v-row class="row--xxl mt-0">
                            <v-col class="pt-0">
                                <!-- Products Tabs -->
                                <template v-if="item.text === '제품'">
                                    <template v-for="(category,index) in categories">
                                        <div :key="index" class="navigation-tab-item-wrap">
                                            <div class="txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px">
                                                {{ category.name }}
                                            </div>
                                            <v-tabs :value="productsTab" vertical class="navigation-tabs navigation-tabs--2">
                                                <template v-for="(child, index) in category.children">
                                                    <template v-if="child.children != 0">
                                                        <v-tab :key="index" class="v-tab--inactive" :class="{ 'v-tab--productActive': productsTab == child.code }" @click.stop.capture="productsTab = child.code">
                                                            <span>
                                                                {{ child.name }}
                                                            </span>
                                                            <v-img max-width="20" src="/images/icon/icon-drawer-arrow.svg" class="ml-4px" />
                                                        </v-tab>
                                                    </template>
                                                    <template v-else>
                                                        <v-tab :key="index" class="v-tab--inactive" :to="`/shop?category=${child.code}`">
                                                            <span>
                                                                {{ child.name }}
                                                            </span>
                                                        </v-tab>
                                                    </template>
                                                </template>
                                            </v-tabs>

                                            
                                        </div>
                                    </template>
                                    <div :key="index" class="navigation-tab-item-wrap">
                                        <div class="txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px">
                                            컬렉션
                                        </div>
                                        <v-tabs :value="productsTab" vertical class="navigation-tabs navigation-tabs--2">
                                            <v-tab :key="index" class="v-tab--inactive" :to="`/collection/bathroomcollection`">
                                                <span>
                                                     욕실 컬렉션
                                                </span>
                                            </v-tab>
                                            <v-tab :key="index" class="v-tab--inactive" :to="`/collection/kitchencollection`">
                                                <span>
                                                     주방 컬렉션
                                                </span>
                                            </v-tab>
                                        </v-tabs>
                                    
                                    </div>
                                    

                                    <!-- <header-collections /> -->
                                </template>
                                <!-- // Products Tabs -->

                                <template v-else>
                                    <template v-for="(child, index) in item.children">
                                        <div :key="index" class="navigation-tab-item-wrap">
                                            <template v-if="child.children">
                                                <div class="txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px">
                                                    {{ child.text }}
                                                </div>
                                                <v-tabs vertical class="navigation-tabs navigation-tabs--2">
                                                    <template v-for="(grandChild, index) in child.children">
                                                        <template v-if="grandChild.children">
                                                            <v-tab :key="index">
                                                                <span>
                                                                    {{ grandChild.text }}
                                                                </span>
                                                                <v-img max-width="20" src="/images/icon/icon-drawer-arrow.svg" class="ml-4px" />
                                                            </v-tab>
                                                        </template>
                                                        <template v-else>
                                                            <v-tab :key="index" :to="grandChild.value" class="v-tab--inactive">
                                                                <span>
                                                                    {{ grandChild.text }}
                                                                </span>
                                                            </v-tab>
                                                        </template>
                                                    </template>
                                                </v-tabs>
                                            </template>
                                            <template v-else>
                                                <router-link :to="child.value" class="txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px">
                                                    {{ child.text }}
                                                </router-link>
                                            </template>
                                        </div>
                                    </template>
                                </template>
                            </v-col>

                            <!-- Products Tabs Items -->
                            <template v-if="item.text === '제품'">
                                <v-col class="products-tabs-items pt-0">
                                    <template v-for="(category, index) in categories">
                                        <v-tabs-items v-model="productsTab" :key="index">
                                            <template v-for="(child, index) in category.children">
                                                <v-tab-item :key="index" :value="child.code">
                                                    <div class="navigation-tab-item-wrap">
                                                        <v-tabs vertical class="navigation-tabs navigation-tabs--3">
                                                            <template v-if="child.code === 'bathroom_fittings'">
                                                                <v-tab to="/shop/bathroom-fittings/overview" class="v-tab--inactive" @click="toggleDrawer">욕실 수전 개요</v-tab>
                                                            </template>
                                                            <template v-for="(grandChild, index) in child.children">
                                                                <v-tab :key="index" :to="`/shop?category=${grandChild.code}`" class="v-tab--inactive" @click="toggleDrawer">
                                                                    <span>
                                                                        {{ grandChild.name }}
                                                                    </span>
                                                                </v-tab>
                                                            </template>
                                                        </v-tabs>
                                                    </div>
                                                </v-tab-item>
                                            </template>
                                        </v-tabs-items>
                                    </template>
                                </v-col>
                            </template>
                            <!-- // Products Tabs Items -->
                        </v-row>
                    </v-tab-item>
                </template>
            </v-tabs-items>
        </v-container>
    </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import navigation from "@/store/ui/index.js";
import headerCollections from './header-drawer/header-collections.vue';

export default {
    components: { headerCollections },
    props: {
        activeTab: Number,
        drawer: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        items: navigation,
        activeTabData: 0,
        productsTab: null,
    }),
    computed: {
        ...mapState(["categories"]),
    },
    methods: {
        toggleDrawer() {
            this.$emit("toggle-drawer");
        },
        init() {
            this.productsTab = this.categories?.[0]?.children?.[0]?.code;
            this.sync();
        },
        sync() {
            this.activeTabData = this.activeTab;
        }
    },
    watch: {
        categories() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.v-btn.header-menu-button {
    padding: 0;
    height: auto !important;
    font-weight: 300;
    font-size: 10px !important;
    font-family: var(--font-secondary);
    line-height: 1;
    &::before {
        display: none;
    }
    .header-menu-icon {
        margin: 0 auto 4px;
    }
    ::v-deep {
        .v-btn__content {
            display: block;
        }
    }
}
.header-drawer {
    width: 100% !important;
    max-width: 800px;
    padding-top: 24px;
    font-family: var(--font-primary);
}
.navigation-tabs {
    ::v-deep {
        .v-tabs-bar {
            height: auto;
        }
    }
    .v-tab {
        width: fit-content;
        height: auto;
        padding: 0;
        text-transform: initial;
        color: var(--v-grey-darken3) !important;
        > span {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 0;
                height: 1px;
                background-color: currentColor;
                transition: all 0.2s;
            }
        }
        &::before {
            display: none;
        }
        &:hover {
            color: #196bdc !important;
        }
        &--active {
            color: #002166 !important;
            > span {
                position: relative;
                &::after {
                    width: 100%;
                }
            }
        }
        &--inactive {
            color: var(--v-grey-darken3) !important;
            > span {
                &::after {
                    display: none;
                }
            }

            &.v-tab--productActive {
                color: #002166 !important;
                > span {
                    &::after {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}
.navigation-tab-item-wrap {
    padding: 24px 0;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 1px;
        background-color: #d9dada;
    }
}
.navigation-tabs--1 {
    margin: 10px -10px 20px;
    font-family: var(--font-tertiary);
    .v-tab {
        font-size: var(--tit-font-size-xxs) !important;
        margin: 0 10px;
    }
}
.navigation-tabs--2 {
    margin: -8px 0;
    .v-tab {
        font-size: var(--txt-font-size-xs) !important;
        font-weight: 300;
        margin: 8px 0;
        padding-left: 8px;
    }
}
.navigation-tabs--3 {
    margin: -8px 0;
    .v-tab {
        font-size: var(--txt-font-size-xs) !important;
        font-weight: 300;
        margin: 8px 0;
    }
}
@media (min-width: 1024px) {
    .header-drawer {
        .container {
            padding: 0 40px;
        }
    }
    .navigation-tabs--1 {
        margin: 20px -20px 30px;
        .v-tab {
            margin: 0 20px;
        }
    }
}
</style>