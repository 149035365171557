<template>
    <div class="d-flex align-center">
        <div class="partnered">partnered with Joeyforlife</div>
        <v-menu class="family-site-list">
            <template #activator="{ on, attrs }">
                <v-card v-bind="{ attrs }" v-on="on" outlined color="grey darken-3" class="family-site-list__button">
                    <div>family sites<v-icon color="grey darken-3">mdi-menu-down</v-icon></div>
                </v-card>
            </template>

            <v-list class="pa-0">
                <template v-for="(item, index) in items">
                    <v-list-item :key="index" :href="item.link" target="_blank">
                        {{ item.name }}
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { attrs_input } from "@/assets/variables";

export default {
    data: () => ({
        attrs_input,
        items: [
            {
                name: "",
                link: "",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.partnered {
    font-size: var(--txt-font-size-sm);
    color: var(--v-grey-darken3);
    font-weight: 300;
    margin-right: 16px;
}
.family-site-list {
    z-index: 999;
    &__button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        width: 120px;
        font-size: 12px;
        line-height: 1;

        .v-icon {
            font-size: 18px;
        }
    }
}
@media (min-width: 768px) {
    .partnered {
        margin-right: 20px;
    }
    .family-site-list {
        &__button {
            height: 44px;
            width: 140px;
            font-size: 14px;

            .v-icon {
                font-size: 20px;
            }
        }
    }
}
</style>