var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    staticClass: "main-story"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("Villeroy-boch in South Korea")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 1748년부터 Villeroy & Boch는 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 사람들의 삶에 세련미와 스타일을 가져다주었습니다. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 이제 Villeroy & Boch가 대한민국과 함께합니다. ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-address.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("서울시 강남구 봉은사로 226 더북컴퍼니빌딩 1층")])])], 1)], 1), _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-phone.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--sm d-inline-block border-b"
  }, [_vm._v("010-3281-3676")])])], 1), _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-mail.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--sm d-inline-block border-b"
  }, [_vm._v("villeroy boch@joeyforlife.com")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.997618149319!2d127.0345016764283!3d37.507974327483126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3ddfc2279bd%3A0x34077c76a1e52a78!2z642U67aB7Lu07Y2864uIIOu5jOuUqQ!5e0!3m2!1sko!2skr!4v1725954572090!5m2!1sko!2skr",
      "width": "100%",
      "height": "170",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }