<template>
    <div class="header-navigation">
        <v-row class="row--lg">
            <template v-for="(item, index) in items">
                <v-col :key="index" cols="auto">
                    <template v-if="item.children">
                        <div class="navigation-item" @click="toggleDrawer(index)">
                            {{ item.text }}
                        </div>
                    </template>
                    <template v-else>
                        <router-link :to="item.value" class="navigation-item">
                            {{ item.text }}
                        </router-link>
                    </template>
                </v-col>
            </template>
        </v-row>

        <header-drawer :drawer="drawer" :activeTab="activeTab" @toggle-drawer="toggleDrawer" />
    </div>
</template>

<script>
import navigation from "@/store/ui/index.js";
import headerDrawer from "./header-drawer.vue";

export default {
    components: { headerDrawer },
    data: () => ({
        items: navigation,
        drawer: false,
        activeTab: 0,
    }),
    methods: {
        toggleDrawer(index) {
            this.drawer = !this.drawer;
            this.activeTab = index;
        },
    },
};
</script>

<style lang="scss" scoped>
.header-navigation {
    font-family: var(--font-tertiary);
    font-size: 20px;
    margin-left: 20px;
    line-height: 1;
    .navigation-item {
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
            color: #196BDC;
        }
    }
}
</style>
