<template>
    <header class="header">
        <v-row no-gutters>
            <v-col cols="auto">
                <h1 class="logo">
                    <router-link to="/" title="홈으로 이동">
                        <v-img src="/images/logo.svg" />
                    </router-link>
                </h1>
            </v-col>
            <v-col>
                <div class="header-inner">
                    <v-row no-gutters justify="end" align="center" class="h-100 align-content-lg-space-between">
                        <v-col cols="12" class="d-none d-lg-block">
                            <header-head />
                        </v-col>
                        <v-col cols="auto" lg="12">
                            <header-body />
                        </v-col>
                    </v-row>
                </div>
            </v-col>

            <!-- Mobile -->
            <v-col cols="12" class="d-lg-none">
                <div class="py-16px">
                    <header-search-bar />
                </div>
            </v-col>
            <!-- // Mobile -->
        </v-row>
    </header>
</template>

<script>
import api from "@/api";
import { DISPLAY_CODES, EXHIBITION_GROUPS } from "@/assets/variables";

// import MainShopCategories from "../main/main-shop-categories.vue";
// import MainShopCategoriesMobile from "../main/main-shop-categories-mobile.vue";

import HeaderHead from "../header/header-head.vue";
import HeaderBody from "../header/header-body.vue";
import HeaderSearchBar from "../header/header-search-bar.vue";

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        // MainShopCategories,
        // MainShopCategoriesMobile,

        HeaderHead,
        HeaderBody,
        HeaderSearchBar,
    },
    data: () => ({
        searchValue: null,
        searchFocused: false,

        groups,
        DISPLAY_CODES,
        logo: null,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { searchValue } = this.$route?.query || {};
            this.searchValue = searchValue;

            let { setting } = await api.v1.setting.get();
            this.logo = setting?.logo?.src;

            this.$nextTick(function () {
                this.header();
            });
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
        search() {
            this.$router.push(`/shop/products?searchValue=${this.searchValue || ""}`);
        },
        onSearchBlur(/** @type {FocusEvent} */ event) {
            setTimeout(() => {
                this.searchFocused = event.target.id == document.activeElement.id;
            }, 200);
        },

        // header
        header: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                    hamburgerCreate();
                }
            }

            // Mobile Header
            let header = document.querySelector(".header"),
                hamburgerBtn = document.querySelector(".mobile-hamburger__btn"),
                searchBtn = document.querySelector(".mobile-header__search"),
                closeSidebar = document.querySelector(".mobile-sidebar__close"),
                closeSearchbar = document.querySelector(".mobile-searchbar__close"),
                dimmed = document.querySelector(".mobile-header-dimmed"),
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");

            hamburgerBtn.addEventListener("click", hamburgerDestroy);
            searchBtn.addEventListener("click", searchDestroy);
            closeSidebar.addEventListener("click", hamburgerCreate);
            closeSearchbar.addEventListener("click", searchCreate);
            dimmed.addEventListener("click", hamburgerCreate);
            dimmed.addEventListener("click", searchCreate);
            mobileCategory.forEach(function (el) {
                el.addEventListener("click", toggleAccordion);
            });

            // Mobile Hamburger
            function hamburgerCreate() {
                let attr = header.getAttribute("data-dimmed");
                if (attr !== null) {
                    hamburgerBtn.setAttribute("aria-pressed", "false");
                    header.setAttribute("data-sidebar", "closed");
                    header.setAttribute("data-dimmed", "closed");
                    mobileCategory.forEach((element) => {
                        element.setAttribute("data-toggle", "closed");
                    });
                }
            }
            function hamburgerDestroy() {
                hamburgerBtn.setAttribute("aria-pressed", "true");
                header.setAttribute("data-sidebar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }
            // Mobile Search
            function searchCreate() {
                // HTML.removeAttribute("data-scroll");
                header.setAttribute("data-searchbar", "closed");
                header.setAttribute("data-dimmed", "closed");
            }
            function searchDestroy() {
                // HTML.setAttribute("data-scroll", "false");
                header.setAttribute("data-searchbar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }

            // Mobile
            function toggleAccordion(el) {
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");
                var targetLi = el.currentTarget.parentNode.classList;
                // var target = el.currentTarget;

                el.preventDefault();
                if (targetLi.contains("opened")) {
                    targetLi.remove("opened");
                } else {
                    mobileCategory.forEach(function (el) {
                        el.parentNode.classList.remove("opened");
                    });
                    targetLi.add("opened");
                }
            }
        },
        closeSidebar() {
            let header = document.querySelector(".header"),
                hamburgerBtn = document.querySelector(".mobile-hamburger__btn"),
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");
            let attr = header.getAttribute("data-dimmed");
            if (attr !== null) {
                hamburgerBtn.setAttribute("aria-pressed", "false");
                header.setAttribute("data-sidebar", "closed");
                header.setAttribute("data-dimmed", "closed");
                mobileCategory.forEach((element) => {
                    element.setAttribute("data-toggle", "closed");
                });
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    watch: {
        "$route.query.searchValue"() {
            this.init();
        },
        "$route.query"() {
            this.closeSidebar();
        },
    },
};
</script>

<style lang="scss" scoped>
// header-inner
.header {
    padding: 0 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
    .logo {
        width: 126px;
    }
    .header-inner {
        height: 100%;
    }
}
@media (min-width: 1024px) {
    .header {
        padding: 0;
        .logo {
            width: 214px;
        }
        .header-inner {
            padding: 20px;
            max-height: 120px;
        }
    }
}
</style>